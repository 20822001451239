@import "@assets/scss/variables.scss";
@import "@assets/scss/mixins.scss";

.downloadBanner {
  padding: 44px 24%;
  background: var(--font-color-dark);
  p {
    margin: 12px 0 10px;
    font-size: 14px;
    line-height: 20px;
    color: $light-color;
    & + p {
      margin-top: 0;
    }
  }
  > :not(.pattern) {
    position: relative;
    z-index: 3;
  }
}
.pattern {
  top: 0;
  z-index: 1;
  @include transform(translate(-65%));
}
.ctIcon {
  width: 28px;
}

/***** Responsive CSS Start ******/

@media (min-width: 00px) {
  .downloadBanner {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../../assets/images/download-banner.png) no-repeat center center / cover;
      z-index: 2;
    }
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .downloadBanner {
    padding: 44px 24%;
  }
}
@media (max-width: 767px) {
  .downloadBanner {
    padding: 10px 25%;
    p {
      margin: 8px 0;
    }
  }
  .ctIcon {
    width: 24px;
  }
}

@media (max-width: 575px) {
  .downloadBanner {
    padding: 10px 20%;
    p {
      font-size: 13px;
      line-height: 19px;
    }
  }
}

